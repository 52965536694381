import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return ['stickyNav', 'mobileOnScreenTrigger', 'desktopOffScreenTrigger']
  }

  initialize() {
    this.mediaQuery = window.matchMedia('(max-width: 767px)')
    this.onScroll(this)
  }

  onScroll(self) {
    if (self.mediaQuery.matches) {
      const clientHeight = self.mobileOnScreenTriggerTarget.getBoundingClientRect().top
      self.toggle(
        clientHeight - (window.innerHeight || document.documentElement.clientHeight) < -20,
      )
    } else {
      const clientHeight = self.desktopOffScreenTriggerTarget.getBoundingClientRect().bottom
      self.toggle(clientHeight < -12)
    }
  }

  onScrollListener(self) {
    return e => this.onScroll(self, e)
  }

  connect() {
    window.addEventListener('scroll', this.onScrollListener(this))
  }

  disconnect() {
    window.removeEventListener('scroll', this.onScrollListener(this))
  }

  toggle(toggle) {
    if (toggle) {
      this.stickyNavTarget.classList.remove('pointer-events-none', 'opacity-0')
    } else {
      this.stickyNavTarget.classList.add('pointer-events-none', 'opacity-0')
    }
  }
}
