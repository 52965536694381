import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return ['tripComparison', 'compareCta', 'compareCount', 'addTrip', 'tripNumber', 'tripData']
  }

  setZeroTrips() {
    this.tripComparisonTarget.innerHTML = ''
    const p = document.createElement('p')
    p.style.textAlign = 'center'
    p.innerHTML = 'No trips to compare<br>'
    const button = document.createElement('a')
    button.setAttribute('class', 'button button--primary mr--top--40')
    button.setAttribute('href', this.element.dataset.compareFallbackUrl)
    button.innerHTML = 'View Trips'
    p.appendChild(button)

    this.tripComparisonTarget.appendChild(p)
  }

  initialize() {
    this.loadCompareFromLocalStorage()

    if (this.hasTripComparisonTarget) {
      if (this.compare.length) {
        this.loadTrips()
      } else {
        this.setZeroTrips()
      }
    } else {
      this.refreshCta({ detail: { count: this.compare.length } })
    }
  }

  loadCompareFromLocalStorage() {
    this.compare = JSON.parse(window.localStorage.getItem('compareTrips') || '[]')
  }

  removeTrip(e) {
    const { tripId } = e.detail

    this.tripComparisonTarget.querySelectorAll(`li[data-trip-id="${tripId}"]`).forEach(elem => {
      elem.remove()
    })

    this.loadCompareFromLocalStorage()
    this.refreshTripNumbers()

    if (this.compare.length < 3) {
      this.addTripTarget.style.display = 'block'
    }

    if (this.compare.length === 0) {
      this.setZeroTrips()
    }
  }

  refreshTripNumbers() {
    this.tripNumberTargets.forEach((tripNumber, i) => {
      tripNumber.innerText = i + 1
    })
  }

  loadTrips() {
    const url = new URL(this.element.dataset.compareSourceUrl)
    this.compare.forEach(tripId => url.searchParams.append('trip[]', tripId))

    fetch(url)
      .then(response => response.text())
      .then(html => {
        this.tripComparisonTarget.innerHTML = html
      })
      .then(() => {
        window.localStorage.setItem('compareTrips', this.tripDataTarget.dataset.tripsLoaded)
      })
      .catch(() => {
        url.searchParams.set('error', 1)
        window.location = url
      })
  }

  refreshCta(e) {
    this.compareCtaTarget.classList.toggle('hidden', e.detail.count === 0)
    this.compareCountTarget.innerText = e.detail.count ? `(${e.detail.count}/3)` : ''
    this.compareCtaTarget.classList.toggle('font-bold', e.detail.count === 3)
  }
}
