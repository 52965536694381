import { Controller } from 'stimulus'

export default class extends Controller {
  initialize() {
    const fragment = window.location.hash.substring(1)

    if (fragment === this.data.get('slug')) {
      this.element.open = true
      this.element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      })
    }
  }
}
