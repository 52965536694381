import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    toggled: { type: Boolean },
  }

  static get targets() {
    return ['activeButton', 'inactiveButton', 'content', 'contentTitle', 'icon', 'border']
  }

  connect() {
    this.toggledValue = this.contentTarget.dataset.togglerToggledValue === 'true'
  }

  toggle() {
    this.toggledValue = !this.toggledValue

    if (this.toggledValue) {
      this.activeButtonTarget.classList.remove('hidden')
      this.inactiveButtonTarget.classList.add('hidden')
      this.contentTarget.classList.remove('hidden')
      if (this.hasContentTitleTarget) {
        this.contentTitleTarget.classList.add('text-raw-sienna')
      }
      if (this.hasIconTarget) {
        this.iconTarget.classList.remove('bg-gray-300')
        this.iconTarget.classList.add('bg-dark-blue')
      }
      if (this.hasBorderTarget) {
        this.borderTarget.classList.add('border-dark-blue')
      }
    } else {
      this.activeButtonTarget.classList.add('hidden')
      this.inactiveButtonTarget.classList.remove('hidden')
      this.contentTarget.classList.add('hidden')
      if (this.hasContentTitleTarget) {
        this.contentTitleTarget.classList.remove('text-raw-sienna')
      }
      if (this.hasIconTarget) {
        this.iconTarget.classList.add('bg-gray-300')
        this.iconTarget.classList.remove('bg-dark-blue')
      }
      if (this.hasBorderTarget) {
        this.borderTarget.classList.remove('border-dark-blue')
      }
    }
  }
}
