import { Controller } from 'stimulus'

export default class extends Controller {
  initialize() {
    if (window.localStorage.getItem('bannerHidden') === this.data.get('id')) {
      this.element.classList.add('is-hidden')
    }
  }

  close() {
    window.localStorage.setItem('bannerHidden', this.data.get('id'))
    this.element.classList.add('is-hidden')
  }
}
