import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return ['text', 'trigger']
  }

  initialize() {
    const textHeight = this.textTarget.offsetHeight

    if (textHeight >= 150) {
      this.triggerTarget.style.display = 'block'
      this.textTarget.classList.add('is-collapsed')
    }
  }

  toggle() {
    this.textTarget.classList.toggle('is-collapsed')

    if (!this.textTarget.classList.contains('is-collapsed')) {
      this.triggerTarget.textContent = 'Read less'
    } else {
      this.triggerTarget.textContent = 'Read more'
    }
  }
}
