import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return ['content', 'form', 'priceRange', 'checkboxFilter', 'apply']
  }

  initialize() {
    this.state = (this.element.dataset.state && JSON.parse(this.element.dataset.state)) || {}
    this.searchParams = {
      include: 'trip_filtering',
    }
  }

  goBack() {
    const url = new URL(window.location.href)

    this.formTarget.reset()

    Array.from(url.searchParams.entries()).forEach(([key, value]) => {
      const elem = this.formTarget.elements[key]
      if (elem.length) {
        Array.from(elem)
          .filter(input => input.value === value)
          .forEach(input => {
            input.checked = true
            input.dispatchEvent(new Event('filterUpdate'))
          })
      } else {
        elem.value = value
        elem.dispatchEvent(new Event('filterUpdate'))
      }
    })

    this.loadTrips(url)
  }

  clear(e) {
    e.preventDefault()

    this.checkboxFilterTargets.forEach(elem => {
      elem.checked = false
    })

    this.priceRangeTarget.dispatchEvent(new Event('reset'))

    this.updateTrips()
  }

  updatePage(e) {
    const url = new URL(e.target.href.split('?')[0])

    this.loadTrips(url).then(() => {
      this.modifyUrl(url)
    })
  }

  updateTrips(e) {
    const parent = this.checkboxFilterTargets.find(
      box => e && box.value === e.target.dataset.parent && box.name === e.target.name,
    )

    if (parent) this.toggleParentState(parent)

    if (this.applyTarget.offsetParent !== null) return

    this.apply()
  }

  apply() {
    const url = new URL(this.formTarget.action)

    this.loadTrips(url).then(() => {
      this.modifyUrl(url)
    })
  }

  sortTrips(e) {
    this.searchParams.sort = e.target.value

    this.apply()
  }

  loadTrips(url) {
    const data = new FormData(this.formTarget)

    Object.entries(this.searchParams).forEach(([k, v]) => {
      url.searchParams.set(k, v)
    })

    Array.from(data.entries()).forEach(([key, value]) => {
      url.searchParams.append(key, value)
    })

    this.contentTarget.style.opacity = 0.4

    return fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        return response.text()
      })
      .then(html => {
        this.contentTarget.innerHTML = html
        this.contentTarget.scrollIntoView()
        this.contentTarget.style.opacity = 1
      })
      .catch(ex => {
        url.searchParams.delete('include')
        window.location = url
      })
  }

  modifyUrl(url) {
    url.searchParams.delete('include')

    const state = { ...this.state, params: url.searchParams.toString() }

    window.history.pushState(state, document.title, url)
  }

  toggleParentState(parent) {
    const siblings = this.checkboxFilterTargets.filter(box => box.dataset.parent === parent.value)

    if (siblings.every(checkbox => checkbox.checked)) {
      if (!parent.checked) parent.checked = true
    } else if (siblings.filter(checkbox => checkbox.checked).length) {
      if (parent.checked) parent.checked = false
    }
  }
}
