import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return ['switcher']
  }

  initialize() {
    if (this.selectedCurrency && this.switcherTargetHasSelectedCurrency) {
      this.switcherTarget.value = this.selectedCurrency
    } else {
      window.localStorage.setItem('currency', this.switcherTarget.value)
      window.dispatchEvent(new Event('currency.change'))
    }
  }

  get selectedCurrency() {
    return window.localStorage.getItem('currency')
  }

  get switcherTargetHasSelectedCurrency() {
    return !!this.switcherTarget.querySelector(`option[value="${this.selectedCurrency}"]`)
  }

  switch(e) {
    window.localStorage.setItem('currency', e.target.value.toLowerCase())

    window.dispatchEvent(new Event('currency.change'))
  }
}
