import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return ['list', 'results', 'input', 'progressIndicator']
  }
  
  initialize() {
    this.latestTimestamp = 0
    this.requestCount = 0
    this.query = this.inputTarget.value
    this.results = null
    this.mouseOver = false
    this.selectionIndex = null
    this.fetchResultsWithDebounce = this.debounceAsync(this.requestResults);

    document.addEventListener('click', e => this.clickHandler(e))
    document.addEventListener('keydown', e => this.keyHandler(e))
  }

  async requestResults() {
    if (this.query.length <= 2) {
      return
    }
    const requestCount = ++this.requestCount
    const timestamp = Date.now()
    this.progressIndicatorTarget.classList.remove('opacity-0')
    const results = await this.fetchResults()

    this.selectionIndex = null
    if (timestamp > this.latestTimestamp) {
      this.latestTimestamp = timestamp
      this.resultsTarget.classList.remove('hidden')
      this.results = results
      this.resultsTarget.innerHTML = results.includes('nav-search__autocomplete-option')
        ? results
        : null
    }
    if (requestCount === this.requestCount) {
      this.progressIndicatorTarget.classList.add('opacity-0')
    }
  }

  onInput(e) {
    this.query = e.target.value
    this.fetchResultsWithDebounce();
  }

  onFocus() {
    if (this.results) {
      this.resultsTarget.innerHTML = this.results
      this.resultsTarget.classList.remove('hidden')
    } else if (this.results === null && this.query.length > 0) {
      this.requestResults()
    }
  }

  onMouseOver() {
    this.mouseOver = true
  }

  onMouseOut() {
    this.mouseOver = false
  }

  clickHandler() {
    if (this.getResultsCount() > 0 && !this.mouseOver) {
      this.clearResults()
    }
  }

  keyHandler(e) {
    if (this.getResultsCount() <= 0) {
      return
    }
    if (e.key === 'Escape') {
      e.preventDefault()
      this.clearResults()
    } else if (e.key === 'ArrowDown' || e.key === 'Tab') {
      this.nextFocus(e)
    } else if (e.key === 'ArrowUp') {
      this.previousFocus(e)
    }
  }

  nextFocus(e) {
    e.preventDefault()
    if (this.selectionIndex === null) {
      this.selectionIndex = 0
      this.focusResultItem()
    } else if (this.selectionIndex < this.getResultsCount() - 1) {
      this.selectionIndex++
      this.focusResultItem()
    } else if (this.selectionIndex === this.getResultsCount() - 1) {
      this.selectionIndex = null
      this.inputTarget.focus()
    }
  }

  previousFocus(e) {
    e.preventDefault()
    if (this.selectionIndex === null) {
      this.selectionIndex = this.getResultsCount() - 1
      this.focusResultItem()
    } else if (this.selectionIndex > 0) {
      this.selectionIndex--
      this.focusResultItem()
    } else if (this.selectionIndex === 0) {
      this.selectionIndex = null
      this.inputTarget.focus()
    }
  }

  focusResultItem() {
    const resultItems = document.querySelectorAll('.nav-search__autocomplete-link')
    if (this.selectionIndex < resultItems.length) {
      const item = resultItems[this.selectionIndex]
      item.focus()
      this.query = item.textContent.trim()
      this.inputTarget.value = this.query
    }
  }

  clearResults() {
    this.resultsTarget.classList.add('hidden')
    this.resultsTarget.innerHTML = null
    this.selectionIndex = null
  }

  getResultsCount() {
    return document.querySelectorAll('.nav-search__autocomplete-link').length
  }

  async fetchResults() {
    try {
      const baseUrl = document.querySelector('link[rel=home]').href ?? '';
      const response = await fetch(`${baseUrl}/autocomplete?q=${this.query}`)
      return await response.text()
    } catch (err) {
      console.error(`Failed to fetch autocomplete results: ${err}`)
      return null
    }
  }
  debounceAsync(func, timeout = 300){
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(async () => { await func.apply(this, args); }, timeout);
    };
  }
}
