import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return ['filter']
  }

  initialize() {}

  toggle() {
    this.filterTarget.classList.toggle('is-shown')
  }

  close() {
    if (this.filterTarget.classList.contains('is-shown')) {
      this.filterTarget.classList.remove('is-shown')
    }
  }
}
