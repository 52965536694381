/* eslint no-underscore-dangle: ["error", { "allow": ["_mfq"] }] */
import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return ['modal', 'form', 'toggle', 'mailchimpFirstName', 'mailchimpLastName']
  }

  connect() {
    this.setContactSource()
  }

  initialize() {
    this.contactSourceFieldName = 'contactSource'
    this.hiddenClassName = 'hidden'
    this.inputParentRowSelector = '.freeform-row'
    this.configureFreeform = this.configureFreeform.bind(this)

    if (this.form.freeform) {
      this.configureFreeform()
    } else {
      this.form.addEventListener('freeform-ready', this.configureFreeform)
    }

    // Allow inputs to toggle other inputs. On the inputs:
    // data-target="form.toggle"
    // data-action="change->form#toggle"
    //
    // The value of the input will then be used to match against the `data-toggle` attribute of an input
    this.hideToggleInputs(this.toggleTargets)
    // Show the default value of any toggle inputs
    this.initialSelectedInput()

    // Hide any Mailchimp first and last name fields from view
    // These are populated by a 'fullname' field on blur
    if (this.hasMailchimpFirstNameTarget) {
      this.mailchimpFirstNameTarget.closest(".freeform-row").style.display = "none";
    }

    if (this.hasMailchimpLastNameTarget) {
      this.mailchimpLastNameTarget.closest(".freeform-row").style.display = "none";
    }
  }

  configureFreeform() {
    this.form.freeform.setOption('renderSuccess', () => {
      this.form.reset()
      this.modal.show(new Event('form.success'))
      window.localStorage.removeItem(this.contactSourceFieldName)
      if (window._mfq && this.form.id) {
        window._mfq.push(['formSubmitAttempt', `#${this.form.id}`])
        window._mfq.push(['formSubmitSuccess', `#${this.form.id}`])
      }
    })
  }

  toggle(event) {
    this.hideToggleInputs()
    this.showToggleInputs([event.currentTarget])
  }

  get form() {
    return this.hasFormTarget ? this.formTarget : this.element
  }

  get modal() {
    return this.application.getControllerForElementAndIdentifier(this.modalTarget, 'modal')
  }

  setContactSource() {
    const extensionField = this.form.querySelector(`[name=${this.contactSourceFieldName}]`)
    const value = window.localStorage.getItem(this.contactSourceFieldName)

    if (extensionField && value) {
      extensionField.value = value
    }
  }

  hideToggleInputs() {
    this.toggleTargets.forEach(input => {
      this.form.querySelectorAll(`[data-toggle=${input.value}]`).forEach(target => {
        target.closest(this.inputParentRowSelector).classList.add(this.hiddenClassName)
      }, this)
    })
  }

  showToggleInputs(inputsArray) {
    inputsArray.forEach(input => {
      this.form.querySelectorAll(`[data-toggle=${input.value}]`).forEach(target => {
        target.closest(this.inputParentRowSelector).classList.remove(this.hiddenClassName)
      }, this)
    })
  }

  initialSelectedInput() {
    const targets = []
    this.toggleTargets.forEach(target => {
      if (target.attributes.checked === undefined) {
        return
      }

      targets.push(target)
    })

    this.showToggleInputs(targets)
  }

  splitFullNameForMailchimp(event) {
    const fullNameSplit = event.target.value.split(" ", 2);

    if (this.hasMailchimpFirstNameTarget) {
      if(fullNameSplit[0]) {
        this.mailchimpFirstNameTarget.value = fullNameSplit[0];
      } else {
        this.mailchimpFirstNameTarget.value = "";
      }
    }

    if (this.hasMailchimpLastNameTarget) {
      if(fullNameSplit[1]) {
        this.mailchimpLastNameTarget.value = fullNameSplit[1];
      } else {
        this.mailchimpLastNameTarget.value = "";
      }
    }
  }
}
