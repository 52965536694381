import Flickity from 'flickity-as-nav-for'
import 'flickity-imagesloaded'
import Controller from './controller'

export default class extends Controller {
  static get targets() {
    return ['slider', 'slides', 'slide']
  }

  static get options() {
    return {
      draggable: true,
      dragThreshold: 10,
      groupCells: false,
      pageDots: false,
      contain: true,
      prevNextButtons: true,
      wrapAround: true,
      imagesLoaded: true,
      cellAlign: 'left',
      setGallerySize: false
    }
  }

  cells = null
  cellSize = null

  toggleSlider = () => {
    if (this.cells && this.cellSize) {
      if (window.innerWidth > this.cells * this.cellSize) {
        this.flickity.deactivate()
        this.sliderTarget.classList.add('trip-slider--deactivated')  
      } else {
        this.flickity.activate()
        this.sliderTarget.classList.remove('trip-slider--deactivated')
      }
    }
  }

  initialize() {
    this.flickity = new Flickity(this.slidesTarget, this.options)

    const role = this.slidesTarget.getAttribute('role')

    if (role) {
      this.flickity.slider.setAttribute('role', role)
      this.slidesTarget.removeAttribute('role')
    }

    // Fix Flickity viewport height calculation (see https://github.com/metafizzy/flickity/issues/205#issuecomment-246212994)
    window.addEventListener('load', () => this.flickity.resize())

    this.flickity.on('settle', () => this.settle())
    this.flickity.on('dragStart', () => this.slidesTarget.classList.add('pointer-events-none'))
    this.flickity.on('dragEnd', () => this.slidesTarget.classList.remove('pointer-events-none'))

    this.cells = this.flickity.cells.length
    this.cellSize = this.flickity.getCellElements()[0].clientWidth

    window.addEventListener('resize', () => {
      this.flickity.resize()
      this.settle()
      this.toggleSlider()
    })

    this.toggleSlider()
  }

  settle() {
    if (this.slideTargets.length === 0) {
      return
    }

    for (let i = 0; i < this.slideTargets.length; i++) {
      const slidePos = this.slideTargets[i].getBoundingClientRect()

      const onScreen =
        Math.round(slidePos.left) >= 0 &&
        Math.round(slidePos.right) <= (window.innerWidth || document.documentElement.clientWidth)

      if (onScreen) {
        this.slideTargets[i].classList.remove('is-offscreen')

        if (i === 0) {
          // If first
          this.sliderTarget.classList.add('trip-slider__inner--at-start')
        } else if (i === this.slideTargets.length - 1) {
          // If last
          this.sliderTarget.classList.add('trip-slider__inner--at-end')
        }
      } else {
        this.slideTargets[i].classList.add('is-offscreen')

        if (i === 0) {
          // If first
          this.sliderTarget.classList.remove('trip-slider__inner--at-start')
        } else if (i === this.slideTargets.length - 1) {
          // If last
          this.sliderTarget.classList.remove('trip-slider__inner--at-end')
        }
      }
    }
  }
}
