import Flickity from 'flickity-as-nav-for'
import 'flickity-imagesloaded'
import Controller from './controller'

export default class extends Controller {
  static get targets() {
    return ['slider', 'slides', 'slide']
  }

  static get options() {
    return {
      draggable: true,
      dragThreshold: 10,
      groupCells: false,
      pageDots: true,
      contain: true,
      prevNextButtons: true,
      wrapAround: true,
      imagesLoaded: true,
      cellAlign: 'center',
      setGallerySize: false,
      lazyLoad: 2
    }
  }

  initialize() {
    this.url = this.element.dataset.url
    this.mobileMediaQuery = window.matchMedia('(max-width: 767px)')

    if (this.mobileMediaQuery.matches) {
      this.options.draggable = false
      this.options.pageDots = false
      this.options.prevNextButtons = false
    }

    this.flickity = new Flickity(this.slidesTarget, this.options)

    // Fix Flickity viewport height calculation (see https://github.com/metafizzy/flickity/issues/205#issuecomment-246212994)
    window.addEventListener('load', () => this.flickity.resize())

    this.flickity.on('dragStart', () => this.slidesTarget.classList.add('pointer-events-none'))
    this.flickity.on('dragEnd', () => this.slidesTarget.classList.remove('pointer-events-none'))
    if (!!this.url) {
      this.flickity.on('staticClick', this.staticClick.bind(this))
    }

    window.addEventListener('resize', () => {
      this.flickity.resize()
    })
  }

  staticClick(e) {
    window.location = this.url
  }

  visitUrlOnMobile() {
    if (!this.url || !this.mobileMediaQuery.matches) { return; }
    window.location = this.url
  }

  visitUrlOnDesktop() {
    if (!this.url || this.mobileMediaQuery.matches) { return; }
    window.location = this.url
  }
}
