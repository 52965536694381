import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['text', 'more', 'less']

  initialize() {
    this.moreLabel = this.element.dataset.more || 'Read more'
    this.lessLabel = this.element.dataset.less || 'Read less'
  }

  showContent() {
    this.lessTarget.textContent = this.lessLabel
    this.textTarget.classList.remove('hidden')
    this.moreTarget.classList.add('hidden')
    this.lessTarget.classList.remove('hidden')
  }

  hideContent() {
    this.moreTarget.textContent = this.moreLabel
    this.textTarget.classList.add('hidden')
    this.moreTarget.classList.remove('hidden')
    this.lessTarget.classList.add('hidden')
  }
}