import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return ['country', 'activity', 'submitButton']
  }

  filter() {
    if (this.hasSubmitButtonTarget) return

    this.submit()
  }

  submit(e) {
    if (e) e.preventDefault()

    this.element.action = this.activityTarget.value

    if (this.countryTarget.value === '') {
      window.location = this.element.action
    } else {
      this.element.submit()
    }
  }
}
