import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['container', 'indicator']

  connect() {
    this.onScroll()
    this.containerTarget.addEventListener('scroll', this.onScroll.bind(this))
    window.addEventListener('resize', this.onScroll.bind(this))
  }

  disconnect() {
    this.containerTarget.removeEventListener('scroll', this.onScroll.bind(this))
    window.removeEventListener('resize', this.onScroll.bind(this))
  }

  onScroll() {
    if (this.isScrolledToRight()) {
      this.indicatorTarget.classList.add('opacity-0', 'pointer-events-none')
    } else {
      this.indicatorTarget.classList.remove('opacity-0', 'pointer-events-none')
    }
  }

  isScrolledToRight() {
    // Check if the scroll position plus the container width is equal to the scroll width
    return (this.containerTarget.scrollLeft + this.containerTarget.clientWidth) >= this.containerTarget.scrollWidth;
  }

  scrollToRight() {
    this.containerTarget.scrollTo({ top: 0, left: this.containerTarget.scrollWidth, behavior: 'smooth' });
  }
}