import { Controller } from 'stimulus'

export default class extends Controller {
  initialize() {
    document.querySelectorAll(`a`).forEach(link => {
      const a = new RegExp(`/${window.location.host}/`)
      if (!a.test(link.href)) {
        link.setAttribute('target', '_blank')
      }
    })
  }
}
