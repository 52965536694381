import { Controller } from 'stimulus'

export default class extends Controller {
  onChange(e) {
    const sections = document.querySelectorAll('[data-section]')
    let closest = sections[0]
    Object.keys(sections).forEach(key => {
      const section = sections[key]
      const closestScrollPosition = Math.abs(closest.getBoundingClientRect().top)
      const sectionScrollPosition = Math.abs(section.getBoundingClientRect().top)
      if (sectionScrollPosition < closestScrollPosition) {
        closest = section
      }
    })
    window.location = `${e.target.value}#${closest.id}`
  }
}
