// This was ported from coffeescript with decaffeinate - it might be weird.
class Transition {
  static initClass() {
    this.instances = []
    this.indexData = '__transition__'
    this.index = 0
  }

  constructor(element, callback, options = {}) {
    this.onTransitionEnd = this.onTransitionEnd.bind(this)

    this.element = element
    this.callback = callback
    this.options = options

    // Set transition reference, clear any active transitions.
    this.init()

    this.play()
  }

  init() {
    this.index = this.element.dataset[Transition.indexData]

    if (this.index != null) {
      if (Transition.instances[this.index] != null) {
        Transition.instances[this.index].cancel(false)
      }
    } else {
      Transition.index += 1
      this.index = Transition.index
      this.element.dataset[Transition.indexData] = this.index
    }

    Transition.instances[this.index] = this
  }

  play() {
    if (!this.options.instant) {
      this.element.addEventListener(this.eventEndName(), this.onTransitionEnd)
    }

    if (this.options.instant) {
      this.after(true)
    }
  }

  after(didComplete) {
    if (this.isCompleted) return
    this.isCompleted = true

    if (Transition.instances[this.index] == null) {
      return
    }

    // Detach transitionend.
    if (!this.options.instant) {
      this.element.removeEventListener(this.eventEndName(), this.onTransitionEnd)
    }

    // delete rather than splice to maintain indexes in sparse array.
    delete Transition.instances[this.index]

    this.callback(didComplete)
  }

  cancel() {
    this.after(false)
  }

  eventEndName() {
    return this.options.animation ? 'animationend' : 'transitionend'
  }

  // Element check avoids reacting to bubbling transitionend events triggered by child elements.
  onTransitionEnd(ev) {
    if (ev.target === this.element) {
      this.after(true)
    }
  }
}

Transition.initClass()

export const transition = (element, options) => new Transition(element, options)

export const noTransition = (element, callback) => {
  element.style.transition = 'none'

  callback(element)

  requestAnimationFrame(() => {
    element.style.transition = ''
  })
}

export default {
  transition,
  noTransition,
}
