import Flickity from 'flickity-as-nav-for'
import 'flickity-imagesloaded'
import Controller from './controller'

export default class extends Controller {
  static get targets() {
    return ['slides', 'slidesNav', 'previous', 'next', 'previousNav', 'nextNav', 'title']
  }

  static get options() {
    return {
      draggable: true,
      dragThreshold: 10,
      pageDots: false,
      prevNextButtons: false,
      imagesLoaded: true,
      wrapAround: true,
    }
  }

  initialize() {
    this.flickity = new Flickity(this.slidesTarget, this.options)
    this.count = this.flickity.cells.length
    this.ajax = this.element.hasAttribute('data-slider-pagination-root')
    this.flickityNav = new Flickity(this.slidesNavTarget, {
      asNavFor: this.slidesTarget,
      contain: true,
      pageDots: false,
      prevNextButtons: false,
    })

    const role = this.slidesTarget.getAttribute('role')

    if (role) {
      this.flickity.slider.setAttribute('role', role)
      this.slidesTarget.removeAttribute('role')
    }

    this.cellSelect()
    this.events()
    this.updateTitle()

    if (this.ajax) {
      this.ajaxInit()
    }
  }

  events() {
    if (this.hasPreviousTarget) {
      this.previousTarget.addEventListener('click', () => this.previous())
    }

    if (this.hasNextTarget) {
      this.nextTarget.addEventListener('click', () => this.next())
    }

    if (this.hasPreviousNavTarget) {
      this.previousNavTarget.addEventListener('click', () => this.previous())
    }

    if (this.hasNextNavTarget) {
      this.nextNavTarget.addEventListener('click', () => this.next())
    }

    // Fix Flickity viewport height calculation (see https://github.com/metafizzy/flickity/issues/205#issuecomment-246212994)
    this.flickity.on('cellSelect', () => this.cellSelect())
    this.flickityNav.on('cellSelect', () => this.cellSelect(true))

    window.addEventListener('load', () => this.flickityNav.resize())
    window.addEventListener('load', () => this.flickity.resize())

    this.flickity.on('change', () => {
      this.updateTitle()

      this.flickity.selectedElement.dispatchEvent(
        new Event('slider-nav.change', {
          bubbles: true,
        }),
      )
    })
  }

  ajaxInit() {
    const thumbWidth = this.flickityNav.cells[0].element.offsetWidth
    const mainWidth = this.flickity.cells[0].element.offsetWidth
    this.currentPage = 1
    this.perPage = 7
    this.loading = false
    this.end = false
    this.progress = [0, 0]

    this.flickityNav.on('dragMove', () => this.dragMove(thumbWidth, 0))
    this.flickity.on('dragMove', () => this.dragMove(mainWidth, 1))

    this.flickityNav.on('scroll', progress => {
      this.progress[0] = Math.max(0, Math.min(1, progress)) * 100
    })

    this.flickity.on('scroll', progress => {
      this.progress[1] = Math.max(0, Math.min(1, progress)) * 100
    })
  }

  dragMove(cellWidth, progress) {
    if (this.isThrottled) {
      return
    }

    this.isThrottled = true

    setTimeout(() => {
      this.isThrottled = false
    }, 500)

    if (this.progress[progress] > 50) {
      this.getNext()
    }
  }

  getNext() {
    if (this.loading === false && this.end === false) {

      this.loading = true
      this.currentPage += 1
      this.element.classList.add('is-ajaxing')

      this.loadCards(this.currentPage)
    }
  }

  getBounds(wrapper) {
    this.viewport = wrapper.querySelector('.flickity-slider')
    this.childPos = this.viewport.getBoundingClientRect()
    this.parentPos = this.viewport.parentNode.getBoundingClientRect()
    this.relativePos = {}

    this.relativePos.top = this.childPos.top - this.parentPos.top
    this.relativePos.right = this.childPos.right - this.parentPos.right
    this.relativePos.bottom = this.childPos.bottom - this.parentPos.bottom
    this.relativePos.left = this.childPos.left - this.parentPos.left
  }

  loadCards(page) {
    const root = this.element.getAttribute('data-slider-pagination-root')
    const urlObj = new URL(root)
    const url = `${urlObj.href.replace(/\/$/, '')}?page=${page}`

    return fetch(url, { mode: 'no-cors' })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        return response.text()
      })
      .then(html => {
        this.injectCards(html)
        this.loading = false
        this.element.classList.remove('is-ajaxing')
      })
      .catch(ex => {
        this.loading = false
        this.element.classList.remove('is-ajaxing')
      })
  }

  injectCards(html) {
    const parser = new DOMParser()
    const htmlDocument = parser.parseFromString(html, 'text/html')
    const biglySlides = htmlDocument.documentElement.getElementsByClassName('bigly-slide')
    const ickleSlides = htmlDocument.documentElement.getElementsByClassName('ickle-slide')

    if (biglySlides.length < this.perPage) {
      this.end = true
    }

    this.flickity.append(biglySlides)
    this.flickityNav.append(ickleSlides)
  }

  select(e) {
    this.flickity.selectCell(`[data-slide-id="${e.detail.slideId}"]`)
  }

  cellSelect(ajax = false) {
    this.count = this.flickity.cells.length
    this.previousTarget.classList.remove('is-disabled')
    this.nextTarget.classList.remove('is-disabled')
    this.previousNavTarget.classList.remove('is-disabled')
    this.nextNavTarget.classList.remove('is-disabled')

    if (this.flickity.cells.length === 1) {
      this.previousTarget.classList.add('is-disabled')
      this.nextTarget.classList.add('is-disabled')
      this.previousNavTarget.classList.add('is-disabled')
      this.nextNavTarget.classList.add('is-disabled')
    }

    if (this.ajax && ajax && this.flickity.selectedIndex > this.flickity.cells.length - 4) {
      this.getNext()
    }
  }

  previous() {
    this.flickity.previous()
  }

  next() {
    this.flickity.next()
  }

  updateTitle() {
    if (!this.hasTitleTarget) return

    this.titleTargets.forEach(title => {
      const isSelectedElement = title.dataset.id === this.flickity.selectedElement.dataset.id
      title.classList.toggle('hidden', !isSelectedElement)
    })
  }
}
