import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return ['buttons']
  }

  trigger(e) {
    if (!window.a2a) { return; }
    e.preventDefault()
    this.buttonsTarget.classList.toggle('opacity-0')
  }

  hide(e) {
    if (!window.a2a) { return; }
    e.preventDefault()
    this.buttonsTarget.classList.add('opacity-0')
  }
}
