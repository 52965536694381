import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return ['wishlistCheckbox', 'yourWishlist', 'counter', 'tripData']
  }

  initialize() {
    this.loadWishlistFromLocalStorage()
    this.initializeCheckboxes()

    if (this.hasYourWishlistTarget) {
      this.loadWishlist()
    }

    this.updateCounter()
  }

  loadWishlistFromLocalStorage() {
    this.wishlist = JSON.parse(window.localStorage.getItem('wishlist') || '[]')
  }

  initializeCheckboxes() {
    this.wishlistCheckboxTargets
      .filter(checkbox => this.wishlist.indexOf(checkbox.dataset.tripId) > -1)
      .forEach(checkbox => {
        checkbox.checked = true
      })
  }

  loadWishlist() {
    const url = new URL(this.element.dataset.wishlistTripsUrl)
    const sharedTripUids = JSON.parse(this.yourWishlistTarget.dataset.shareUids || 'null')
    const wishlist = sharedTripUids || this.wishlist

    if (wishlist.length === 0) {
      this.displayEmptyText()
      return
    }

    wishlist.forEach(tripId => url.searchParams.append('trip[]', tripId))

    if (sharedTripUids) {
      url.searchParams.set('read_only', 1)
    }

    fetch(url)
      .then(response => response.text())
      .then(html => {
        this.yourWishlistTarget.innerHTML = html
        if (!sharedTripUids) {
          window.localStorage.setItem('wishlist', this.tripDataTarget.dataset.tripsLoaded)
          this.setShareUrl(JSON.parse(this.tripDataTarget.dataset.tripsLoaded))
        }
      })
  }

  toggleWishlist(e) {
    if (e.currentTarget.checked) {
      this.addToWishlist(e.target.dataset.tripId)
    } else {
      this.removeFromWishlist(e.target.dataset.tripId)
    }
  }

  mutateWishlist(action) {
    this.loadWishlistFromLocalStorage()

    action.call()

    this.updateCounter()

    window.localStorage.setItem('wishlist', JSON.stringify(this.wishlist))
  }

  addToWishlist(tripId) {
    this.mutateWishlist(() => {
      const index = this.wishlist.indexOf(tripId)
      const inWishlist = index > -1

      if (inWishlist) return

      this.wishlist.push(tripId)
    })
  }

  removeFromWishlist(tripId) {
    this.mutateWishlist(() => {
      const index = this.wishlist.indexOf(tripId)
      const inWishlist = index > -1

      if (!inWishlist) return

      this.wishlist.splice(index, 1)
    })
  }

  removeFromWishlistAndDom(e) {
    this.removeFromWishlist(e.currentTarget.dataset.tripId)

    e.currentTarget.closest('.card-wishlist').remove()

    if (this.wishlist.length === 0) {
      this.displayEmptyText()
    }
  }

  displayEmptyText() {
    this.yourWishlistTarget.innerText = this.yourWishlistTarget.dataset.noTripsText
  }

  updateCounter() {
    this.counterTarget.innerText = this.wishlist.length
  }

  setShareUrl(sharedTripUids) {
    const shareUrl = new URL(`${window.location.origin}/wishlist/share`)

    shareUrl.searchParams.set('trips', sharedTripUids.join(','))
  }
}
