import Flickity from 'flickity-as-nav-for'
import 'flickity-imagesloaded'
import Controller from './controller'

export default class extends Controller {
  static get targets() {
    return ['slides', 'slide', 'previous', 'next']
  }

  static get options() {
    return {
      draggable: true,
      dragThreshold: 10,
      groupCells: true,
      pageDots: false,
      contain: true,
      prevNextButtons: false,
      wrapAround: false,
      imagesLoaded: true,
    }
  }

  initialize() {
    this.flickity = new Flickity(this.slidesTarget, this.options)

    const role = this.slidesTarget.getAttribute('role')

    if (role) {
      this.flickity.slider.setAttribute('role', role)
      this.slidesTarget.removeAttribute('role')
    }

    this.cellSelect()

    if (this.hasPreviousTarget) {
      this.previousTarget.addEventListener('click', () => this.previous())
    }

    if (this.hasNextTarget) {
      this.nextTarget.addEventListener('click', () => this.next())
    }

    // Fix Flickity viewport height calculation (see https://github.com/metafizzy/flickity/issues/205#issuecomment-246212994)
    window.addEventListener('load', () => this.flickity.resize())

    this.flickity.on('cellSelect', nothing => this.cellSelect(nothing))
    this.flickity.on('settle', () => this.settle())
  }

  cellSelect() {
    this.previousTarget.classList.remove('is-disabled')
    this.nextTarget.classList.remove('is-disabled')

    if (this.flickity.selectedIndex === 0) {
      this.previousTarget.classList.add('is-disabled')
    }

    if (this.flickity.selectedIndex === this.flickity.slides.length - 1) {
      this.nextTarget.classList.add('is-disabled')
    }
  }

  previous() {
    this.flickity.previous()
  }

  next() {
    this.flickity.next()
  }

  settle() {
    if (this.slideTargets.length === 0) {
      return
    }

    for (let i = 0; i < this.slideTargets.length; i++) {
      const slidePos = this.slideTargets[i].getBoundingClientRect()

      if (
        slidePos.left >= 0 &&
        slidePos.right <= (window.innerWidth || document.documentElement.clientWidth)
      ) {
        this.slideTargets[i].classList.remove('is-offscreen')
      } else {
        this.slideTargets[i].classList.add('is-offscreen')
      }
    }
  }
}
