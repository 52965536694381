import Controller from './controller'
import { transition } from '../transition'

export default class extends Controller {
  static get targets() {
    return ['layer', 'media']
  }

  initialize() {
    this.expandedClass = 'is-expanded'
    this.selectedClass = 'is-selected'
    this.wasSelectedClass = 'was-selected'

    this.element.addEventListener('mouseenter', () => {
      this.isMousedOver = true
      this.setExpandedState()
    })
    this.element.addEventListener('mouseleave', () => {
      this.isMousedOver = false
      this.setExpandedState()
    })
    this.element.addEventListener('focusin', ev => {
      clearTimeout(this.focusOutTimeout)
      this.focusedElement = ev.target
      this.setExpandedState()
    })
    this.element.addEventListener('focusout', () => {
      this.focusOutTimeout = setTimeout(() => {
        this.focusedElement = null
        this.setExpandedState()
      }, 50)
    })
  }

  setExpandedState() {
    if (this.isMousedOver || (this.focusedElement && this.element.contains(this.focusedElement))) {
      this.expand()
    } else {
      this.contract()
    }
  }

  expand() {
    if (this.isExpanded) return
    this.isExpanded = true

    if (!this.isAnimating) {
      this.layerTarget.style.willChange = 'transform'
      this.mediaTarget.style.willChange = 'transform'
    }

    this.isAnimating = true

    transition(this.layerTarget, () => {
      this.isAnimating = false
    })

    this.elementSize = this.element.getBoundingClientRect()
    this.mediaSize = this.mediaTarget.getBoundingClientRect()

    // Base the scale offset on the `top` value from the `.card__media` CSS
    const offset = parseInt(getComputedStyle(this.mediaTarget).top, 10) * -2

    const layerTargetWidth = this.elementSize.width + offset
    const layerTargetHeight = this.elementSize.height + offset

    const layerScaleX = layerTargetWidth / this.elementSize.width
    const layerScaleY = layerTargetHeight / this.elementSize.height

    const mediaScaleX = this.elementSize.width / layerTargetWidth
    const mediaScaleY = this.elementSize.height / layerTargetHeight
    const mediaOriginY = (layerTargetHeight / 2 / this.mediaSize.height) * 100

    this.mediaTarget.style.transformOrigin = `50% ${mediaOriginY}%`
    this.mediaTarget.style.transform = `scale(${mediaScaleX}, ${mediaScaleY})`
    this.layerTarget.style.transform = `scale(${layerScaleX}, ${layerScaleY})`

    this.element.classList.add(this.expandedClass)

    // This class is used to prevent the checked checkbox moving when expanding the card (otherwise
    // it can be frustrating to uncheck, as it can move when mousing toward it)
    if (this.isSelected) {
      this.element.classList.add(this.wasSelectedClass)
    }
  }

  contract() {
    if (!this.isExpanded) return
    this.isExpanded = false

    this.isAnimating = true

    transition(this.layerTarget, didComplete => {
      this.isAnimating = false

      if (didComplete) {
        this.layerTarget.style.willChange = ''
        this.mediaTarget.style.willChange = ''
      }
    })

    this.mediaTarget.style.transform = ''
    this.layerTarget.style.transform = ''

    this.element.classList.remove(this.expandedClass)
    this.element.classList.remove(this.wasSelectedClass)
  }

  toggleSelected(ev) {
    if (ev.target.checked) {
      this.isSelected = true
      this.element.classList.add(this.selectedClass)
    } else {
      this.isSelected = false
      this.element.classList.remove(this.selectedClass)
    }
  }
}
