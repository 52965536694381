export const isTouch =
  window.matchMedia('(hover: none)').matches || window.matchMedia('(pointer: coarse)').matches

export const isReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches

export const debounce = function(func, wait, immediate) {
  let timeout
  return function(...args) {
    const context = this
    const later = function() {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

export const sleep = delay => new Promise(resolve => setTimeout(resolve, delay));