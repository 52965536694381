import wNumb from 'wnumb'
import noUiSlider from 'nouislider'
import Controller from './controller'

export default class extends Controller {
  static get targets() {
    return ['slider', 'inputMin', 'inputMax']
  }

  get options() {
    return {
      orientation: 'horizontal',
      start: [this.inputMinTarget.value || 6, this.inputMaxTarget.value || 21],
      step: 1,
      tooltips: [
        wNumb({
          decimals: 0,
          prefix: 'From: ',
          suffix: 'd',
        }),
        wNumb({
          decimals: 0,
          prefix: 'To: ',
          suffix: 'd',
        }),
      ],
      range: {
        min: Number(this.inputMinTarget.dataset.minValue) || 1,
        max: Number(this.inputMaxTarget.dataset.maxValue) || 31,
      },
    }
  }

  initialize() {
    noUiSlider.create(this.sliderTarget, this.options)

    this.sliderTarget.noUiSlider.on('change', values => {
      this.sliderTarget.dispatchEvent(new CustomEvent('change', { detail: values }))
    })

    this.sliderTarget
      .querySelector('.noUi-handle-lower')
      .setAttribute('aria-label', 'Minimum duration')

    this.sliderTarget
      .querySelector('.noUi-handle-upper')
      .setAttribute('aria-label', 'Maximum duration')

    this.sliderTarget.noUiSlider.on('update', this.updateInput.bind(this))
  }

  refreshSlider() {
    this.sliderTarget.noUiSlider.set([this.inputMinTarget.value, this.inputMaxTarget.value])
  }

  reset() {
    this.sliderTarget.noUiSlider.reset()
  }

  decrease() {
    const number = this.sliderTarget.noUiSlider.get()[0]
    const decreasedVal = number - 1
    this.sliderTarget.noUiSlider.set([decreasedVal, null])
    this.inputMinTarget.value = decreasedVal
  }

  increase() {
    const number = this.sliderTarget.noUiSlider.get()[1]
    const increasedVal = Number(number) + 1
    this.sliderTarget.noUiSlider.set([null, increasedVal])
    this.inputMaxTarget.value = increasedVal
  }

  updateInput() {
    const [min, max] = this.sliderTarget.noUiSlider.get()

    this.inputMinTarget.value = min
    this.inputMaxTarget.value = max
  }
}
