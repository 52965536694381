import { Controller } from 'stimulus'
import marked from 'marked'
import mapStyles from '../map-styles'

export default class extends Controller {
  initialize() {
    const kmlUrl = this.data.get('kml-url')

    if (!kmlUrl) {
      console.warn('Maps KML URL not provided')
      return
    }

    Promise.all([this.fetchKml(kmlUrl), this.googleMapsApiReady()]).then(() => {
      this.parseKml(this.kml)
    })
  }

  async fetchKml(url) {
    const response = await fetch(`${url}&_cachebust=${Date.now()}`)
    if (!response.ok) {
      console.warn('Maps KML could not be fetched', url)
      return
    }

    this.kml = await response.text()
  }

  async googleMapsApiReady() {
    return new Promise(resolve => {
      if (window.google && window.google.maps) {
        resolve()
      } else {
        document.addEventListener('mapsReady', () => {
          resolve()
        })
      }
    })
  }

  createGMap() {
    // UI controls are disabled by default (for small thumbnail maps)
    const options = {
      styles: mapStyles,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      zoomControl: false,
    }

    // UI controls can enabled by setting a `data-map-enable-controls` attribute
    if (this.data.get('enableControls')) {
      options.fullscreenControl = true
      options.zoomControl = true
    }

    this.gMap = new window.google.maps.Map(this.element, options)
  }

  renderLine(feature) {
    const name = feature.querySelector('name').textContent
    const lineStyles = this.lineStylesForType(name)

    // eslint-disable-next-line no-new
    new window.google.maps.Polyline({
      map: this.gMap,
      path: this.coordinatesFromFeature(feature),
      ...lineStyles,
    })
  }

  renderShape(feature) {
    const coordinates = this.coordinatesFromFeature(feature)
    const styles = feature.querySelector('styleUrl').textContent.split('-')
    // eslint-disable-next-line prefer-const
    let [, color, strokeWeight, opacity] = styles

    // Approx values to match MyMaps styles, may need updating
    strokeWeight = this.mapValueToRange(parseFloat(strokeWeight), 1, 16000, 0, 15)
    opacity = this.mapValueToRange(parseFloat(opacity), 0, 255, 0, 1)

    let title = feature.querySelector('name')
    if (title) title = title.textContent.trim()

    let content = feature.querySelector("Data[name='description']")
    if (!content) content = feature.querySelector('description')
    if (content) content = content.textContent.trim()
    if (content) content = marked(content)

    const shape = new window.google.maps.Polygon({
      map: this.gMap,
      paths: coordinates,
      strokeColor: `#${color}`,
      strokeWeight,
      fillColor: `#${color}`,
      fillOpacity: opacity,
      clickable: !!(title || content),
    })

    if (title || content) {
      const bounds = new window.google.maps.LatLngBounds()
      coordinates.forEach(latLng => {
        bounds.extend(latLng)
      })
      const center = bounds.getCenter()

      shape.addListener('click', () => {
        this.setInfoWindowContent(title, content)
        this.infoWindow.setPosition(center)
        this.infoWindow.open(this.gMap)
      })
    }
  }

  renderMarker(feature) {
    const styleUrl = feature.querySelector('styleUrl').textContent

    // Hack to filter out automatic markers at start and end of plotted directions in My Maps
    if (styleUrl === '#icon-1899-DB4436-nodesc') {
      return null
    }

    const coordinates = this.coordinatesFromFeature(feature)[0]
    const iconStyle = styleUrl.split('-')
    const fill = `#${iconStyle[2]}`

    let title = feature.querySelector('name')
    if (title) title = title.textContent.trim()

    let content = feature.querySelector("Data[name='description']")
    if (!content) content = feature.querySelector('description')
    if (content) content = content.textContent.trim()
    if (content) content = marked(content)

    const marker = new window.google.maps.Marker({
      title,
      clickable: !!(title || content),
      position: coordinates,
      map: this.gMap,
      icon: {
        size: new window.google.maps.Size(16, 24),
        anchor: new window.google.maps.Point(7, 20),
        scaledSize: new window.google.maps.Size(16, 24),
        url: this.markerIcon(fill),
      },
    })

    if (title || content) {
      marker.addListener('click', () => {
        this.setInfoWindowContent(title, content, marker)
        this.infoWindow.open(this.gMap, marker)
      })
    }

    return marker
  }

  setInfoWindowContent(title, content) {
    let text = ``
    if (title) text += `<h3>${title}</h3>`
    if (content) text += `<p>${content}</p>`

    this.infoWindow = this.infoWindow || new window.google.maps.InfoWindow()
    this.infoWindow.setContent(`<div class="info-window">${text}</div>`)
  }

  markerIcon(fill) {
    const icon = `<svg width="8" height="12" viewBox="0 0 8 12" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.61 1.5A3.643 3.643 0 000 5.142c.005.681.202 1.347.57 1.92l2.759 3.865a.345.345 0 00.281.21.34.34 0 00.282-.21l2.759-3.864c.367-.573.565-1.24.57-1.92a3.641 3.641 0 00-3.61-3.645z" fill="${fill}"/>
      <path d="M5.056 5.114a1.445 1.445 0 11-2.89.001 1.445 1.445 0 012.89-.001z" fill="#FFF"/>
    </svg>`

    return `data:image/svg+xml;charset=UTF-8;base64,${btoa(icon)}`
  }

  lineStylesForType(type) {
    // Solid line for directions (directions paths are too detailed for dashed lines to look good)
    if (type.indexOf('Directions ') === 0) {
      return {
        strokeColor: '#4e3836',
        strokeWeight: 3,
        strokeOpacity: 0.8,
      }
    }

    // Dashed line
    return {
      strokeOpacity: 0,
      icons: [
        {
          icon: {
            path: 'M 0,-1 0,1',
            strokeOpacity: 1,
            strokeColor: '#4e3836',
            strokeWeight: 2,
            scale: 4,
          },
          offset: '0',
          repeat: '20px',
        },
      ],
    }
  }

  parseKml(kml) {
    const parser = new DOMParser()
    const data = parser.parseFromString(kml, 'application/xml')
    const features = data.querySelectorAll('Placemark')
    const markers = []

    this.bounds = new window.google.maps.LatLngBounds()
    this.createGMap()

    features.forEach(feature => {
      if (feature.querySelector('Point')) {
        const marker = this.renderMarker(feature)
        if (marker) {
          markers.push(marker)
        }
      } else if (feature.querySelector('LineString')) {
        this.renderLine(feature)
      } else if (feature.querySelector('Polygon')) {
        this.renderShape(feature)
      }
    })

    if (features.length === 1 && markers.length === 1) {
      // If there's only a single marker, don't zoom in all the way
      this.gMap.setCenter(markers[0].getPosition())
      this.gMap.setZoom(5) // Kind of arbitrary zoom level - may need to change per map
    } else if (features.length === 0) {
      console.warn('Map data has no features')
    } else {
      // Fit all features in view
      this.gMap.fitBounds(this.bounds)
      this.gMap.panToBounds(this.bounds)
    }
  }

  coordinatesFromFeature(feature) {
    let coordinates = feature.querySelector('coordinates').textContent
    coordinates = coordinates
      .trim()
      .split(/\n/)
      .map(line => {
        const parts = line
          .trim()
          .replace(/,0$/, '')
          .split(',')
          .map(Number)

        const latLng = {
          lat: parts[1],
          lng: parts[0],
        }

        this.bounds.extend(latLng)

        return latLng
      })
    return coordinates
  }

  mapValueToRange(value, fromMin, fromMax, toMin, toMax) {
    return toMin + ((value - fromMin) / (fromMax - fromMin)) * (toMax - toMin)
  }
}
