import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return ['trigger', 'menu']
  }

  initialize() {
    this.open = false
    this.dropdowns = document.querySelectorAll('[data-controller*="dropdown"]')
    this.menus = document.querySelectorAll('[data-target*="dropdown.menu"]')
    this.triggers = document.querySelectorAll('[data-target*="dropdown.trigger"]')
  }

  trigger() {
    clearTimeout(this.hideOverflowTimeout)

    if (this.element.classList.contains('is-hidden')) {
      this.closeAll()
      this.setOpenState(true)
      this.element.classList.remove('is-hidden')
    } else {
      this.closeAll()
    }
  }

  closeAll() {
    this.open = false

    for (let i = 0; i < this.triggers.length; i++) {
      this.triggers[i].classList.remove('is-open')
    }

    for (let i = 0; i < this.menus.length; i++) {
      this.menus[i].classList.remove('is-open')
    }

    for (let i = 0; i < this.menus.length; i++) {
      this.dropdowns[i].classList.add('is-hidden')
    }
  }

  close() {
    this.setOpenState(false)

    this.hideOverflowTimeout = setTimeout(() => {
      this.element.classList.add('is-hidden')
    }, 300)
  }

  setOpenState(open) {
    this.open = open

    this.triggerTarget.classList.toggle('is-open', this.open)

    if (this.hasMenuTarget) {
      this.menuTarget.classList.toggle('is-open', this.open)
    }
  }
}
