import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return ['close', 'inner', 'content', 'data', 'bg', 'templateContent']
  }

  initialize() {
    this.show = this.show.bind(this)
    this.hide = this.hide.bind(this)
    this.disableScroll = this.data.get('scroll') === undefined

    const modalTrigger = this.contentTarget.querySelector('a[href="#modal"]')
    if (modalTrigger) {
      modalTrigger.addEventListener('click', this.show)
    }

    if (this.hasDataTarget) {
      this.selectContent()
    }

    this.openFromFragment()

    document.addEventListener('keydown', this.hide)
  }

  openFromFragment() {
    const fragment = window.location.hash.substring(1)

    if (fragment) {
      if (this.selectContent(fragment)) {
        this.show(new Event('click'))
      }
    }
  }

  show(event) {
    event.preventDefault()

    if (this.disableScroll) {
      document.getElementsByTagName('html')[0].classList.add('is-scrolllocked')
    }

    if (this.isOpen) return

    this.addOffScreenClasses()
    this.contentTarget.classList.add('is-open')

    if (this.hasBgTarget) {
      this.bgTarget.style.display = 'block'
    }

    if (event.currentTarget && event.currentTarget.dataset.templateId) {
      this.populateContentFromTemplate(event.currentTarget.dataset.templateId)
    }

    if (event.currentTarget && event.currentTarget.dataset.slideId) {
      this.selectContent(event.currentTarget.dataset.slideId)
    }

    if (this.disableScroll) {
      disableBodyScroll(this.modalTarget, {
        reserveScrollBarGap: true,
      })
    }

    this.closeTargets.forEach(element => {
      element.addEventListener('click', this.hide)
    })

    this.element.dispatchEvent(new CustomEvent('modal.open', { bubbles: true }))
  }

  hide(event) {
    if (this.disableScroll) {
      document.getElementsByTagName('html')[0].classList.remove('is-scrolllocked')
    }

    if (event && event.keyCode && event.keyCode !== 27) return

    this.contentTarget.classList.remove('is-open')

    if (this.hasBgTarget) {
      this.bgTarget.style.display = 'none'
    }

    if (this.disableScroll) {
      enableBodyScroll(this.modalTarget)
    }

    this.closeTarget.removeEventListener('click', this.hide)
  }

  closeOther(e) {
    if (e.target !== this.element && this.isOpen) {
      this.hide()
    }
  }

  changeContent(e) {
    this.selectContent(e.target.dataset.slideId)
  }

  selectContent(slideId) {
    this.dataTargets.forEach(field => {
      field.classList.toggle('hidden', this.isFieldHiddenOnSlide(field, slideId))
    })

    this.element.dispatchEvent(
      new CustomEvent('modal.selectSlide', {
        bubbles: true,
        detail: { slideId },
      }),
    )

    return this.dataTargets.find(field => !field.classList.contains('hidden'))
  }

  populateContentFromTemplate(templateId) {
    const template = this.element.querySelector(`#modal-template-${templateId}`)

    if (template) {
      this.templateContentTarget.innerHTML = ''
      this.templateContentTarget.appendChild(template.content.cloneNode(true))
    }
  }

  isFieldHiddenOnSlide(field, slideId) {
    if (slideId) return field.dataset.slideId !== slideId

    return Array.from(field.parentNode.children).indexOf(field) > 0
  }

  get isOpen() {
    return this.contentTarget.classList.contains('is-open')
  }

  addOffScreenClasses() {
    this.contentTarget.classList.remove('is-offscreen--right', 'is-offscreen--left')

    const contentPos = this.contentTarget.getBoundingClientRect()

    if (contentPos.right > (window.innerWidth || document.documentElement.clientWidth)) {
      this.contentTarget.classList.add('is-offscreen--right')
    } else if (contentPos.left < 0) {
      this.contentTarget.classList.add('is-offscreen--left')
    }
  }
}
