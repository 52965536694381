import { Controller } from 'stimulus'

export default class extends Controller {
  selectSort(e) {
    const value = e.target.value
    if (!value) { return }
    var currentUrl = new URL(window.location.href)
    if (currentUrl.searchParams.has('sort')) {
      currentUrl.searchParams.delete('sort')
    }
    currentUrl.searchParams.set('sort', e.target.value)
    window.location.href = currentUrl.href
  }
}
