import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return ['content', 'list', 'tab']
  }

  initialize() {
    this.setInitialState()
    this.selectedTabs = { ...this.initialState }
    this.openStateClick = this.openStateClick.bind(this)
  }

  shortcutTab(e) {
    // if the link already has an action, return; it's likely a tab link
    if (e.target.dataset.action) return

    // otherwise, find the related tab...
    const similarLinks = this.element.querySelectorAll(`[href="${e.target.href}"]`)
    const tabLink = Array.from(similarLinks).find(link => this.tabTargets.includes(link.parentNode))

    if (!tabLink) return

    e.preventDefault()

    // ...and click it
    tabLink.dispatchEvent(new Event('click'))
  }

  async switchTab(e) {
    e.preventDefault()

    let tabTarget = e.currentTarget.parentNode
    let tabHref = e.currentTarget.href

    if (e.currentTarget instanceof HTMLSelectElement) {
      ;[tabTarget] = e.currentTarget.selectedOptions
      tabHref = tabTarget.value
    }

    // If tab is currently open: toggle open/closed state (for mobile styling)
    if (this.isTabActive(tabTarget)) {
      this.toggleOpenState()
      return
    }

    // Optimistically add active state to the new tab, as otherwise the dropdown behaviour feels laggy on mobile
    this.setActiveTab(tabTarget)

    // Close tabs when selecting a new tab
    this.unsetOpenState()
    const topOfElement = window.pageYOffset + this.element.getBoundingClientRect().top - 68
    window.scroll({ top: topOfElement })

    const { block } = tabTarget.dataset

    if (!block) {
      throw new Error('Missing data-block attribute on tab target')
    }

    await this.loadTabContent(tabHref, tabTarget).then(() => {
      this.selectedTabs[block] = this.tabsForBlock(block).indexOf(tabTarget)
      const pageTitle = tabTarget.dataset.pageTitle || e.target.innerText
      document.title = pageTitle
      window.history.pushState({ tabs: this.selectedTabs }, pageTitle, this.responseUrl)
    })
  }

  async loadTabContent(url, tab) {
    // If this tab has been visited before then load its content from memory instead of fetching again
    if (this.visitedTabContent.has(tab)) {
      return new Promise(resolve => {
        const html = this.visitedTabContent.get(tab)
        this.setTabContent(tab, html)

        const link = tab.querySelector('a')
        if (link) {
          this.responseUrl = link.href
        }

        resolve()
      })
    }

    this.setLoadingState(tab)

    url = new URL(url)

    url.searchParams.set('include', tab.dataset.block)

    return fetch(url)
      .then(response => {
        this.responseUrl = response.url.replace(/\?.+$/, '')
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        return response.text()
      })
      .then(html => {
        this.setTabContent(tab, html)
        this.visitedTabContent.set(tab, html)
        this.unsetLoadingState(tab)
      })
      .catch(ex => {
        window.location = this.responseUrl
      })
  }

  setLoadingState(tab) {
    const tabContentTarget = this.contentTargetForTab(tab)
    tabContentTarget.classList.add('is-loading')
  }

  unsetLoadingState(tab) {
    const tabContentTarget = this.contentTargetForTab(tab)
    tabContentTarget.classList.remove('is-loading')
  }

  setTabContent(tab, html) {
    const content = this.contentTargetForTab(tab)
    content.innerHTML = html
    this.setActiveTab(tab)
    content.dispatchEvent(new CustomEvent('tabContentLoaded', { bubbles: true }))
  }

  setInitialState() {
    this.initialState = {}

    // Keep a store of the content for visited tabs in memory. This is used when navigating to a previously-viewed tab
    // to avoid fetching its content again
    this.visitedTabContent = new WeakMap()

    this.tabTargets
      .filter(tab => tab.classList.contains('is-active'))
      .forEach(tab => {
        const { block } = tab.dataset
        this.initialState[block] = this.tabsForBlock(block).indexOf(tab)

        // Store the current active tab's content
        const tabContentTarget = this.contentTargetForTab(tab)
        this.visitedTabContent.set(tab, tabContentTarget.innerHTML)
      })
  }

  returnToTab(e) {
    const state = (e.state && e.state.tabs) || this.initialState

    let tabToSelect = null

    Object.entries(state).find(([block, index]) => {
      tabToSelect = this.tabsForBlock(block)[index]
      return tabToSelect && !tabToSelect.classList.contains('is-active')
    })

    if (tabToSelect) this.loadTabContent(document.location, tabToSelect)
  }

  tabsForBlock(block) {
    return this.tabTargets.filter(t => t.dataset.block === block)
  }

  contentTargetForTab(tab) {
    return this.contentTargets.find(element => element.dataset.block === tab.dataset.block)
  }

  setActiveTab(selectedTab) {
    this.tabsForBlock(selectedTab.dataset.block).forEach(tab => {
      tab.classList.remove('is-active')
    })

    selectedTab.classList.add('is-active')
  }

  isTabActive(tab) {
    return tab.classList.contains('is-active')
  }

  toggleOpenState() {
    if (this.isOpen) {
      this.unsetOpenState()
    } else {
      this.setOpenState()
    }
  }

  setOpenState() {
    if (this.isOpen) return
    this.isOpen = true

    this.listTarget.style.height = `${this.listTarget.offsetHeight}px`
    this.listTarget.classList.add('is-open')

    // While tabs are open: clicking outside should close them again
    window.addEventListener('click', this.openStateClick)
  }

  unsetOpenState() {
    if (!this.isOpen) return
    this.isOpen = false

    this.listTarget.style.height = ''
    this.listTarget.classList.remove('is-open')

    window.removeEventListener('click', this.openStateClick)
  }

  openStateClick(ev) {
    if (!this.listTarget.contains(ev.target)) {
      this.unsetOpenState()
    }
  }
}
