import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return [
      'base',
      'bg',
      'mainMenu',
      'subMenu',
      'nestedSubMenu',
      'newHeight',
      'rotateSubMenu',
      'rotateNestedMenu',
    ]
  }

  initialize() {
    this.banner = document.querySelector('[data-controller*="banner"]')

    if (this.banner) {
      const close = this.banner.querySelector('.close')

      window.addEventListener('resize', () => this.resize())
      close.addEventListener('click', () => setTimeout(() => this.resize(), 10))
    }
  }

  resize() {
    this.baseTarget.style.height = `calc(100% - ${this.banner.offsetHeight + 91}px)`
    this.baseTarget.style.top = `${this.banner.offsetHeight + 91}px`
    this.bgTarget.style.top = `${this.banner.offsetHeight + 91}px`
  }

  toggle() {
    if (this.banner) {
      this.resize()
    }

    document.documentElement.classList.toggle('overflow-hidden')
    this.element.classList.toggle('is-open')
  }

  openSubMenu(e) {
    const element = e.currentTarget.nextElementSibling // menu.subMenu
    element.classList.toggle('is-open')

    // sets height of mobile-navigation__dropdown
    const elementIndex = Number(e.currentTarget.dataset.index)
    const elementHeight = 36 * elementIndex
    element.style.height = `calc(100% - ${elementHeight}px)`

    // closes all nested menus
    this.nestedSubMenuTargets.forEach(elem => {
      if (elem.classList.contains('is-open')) {
        elem.classList.remove('is-open')
      }
    })

    // resets all nested menu triangles
    this.rotateNestedMenuTargets.forEach(elem => {
      if (elem.style.transform === `rotate(180deg)`) {
        elem.style.transform = `none`
      }
    })

    // rotates triangle
    if (e.currentTarget.firstElementChild.style.transform === `rotate(180deg)`) {
      e.currentTarget.firstElementChild.style.transform = `none`
    } else {
      e.currentTarget.firstElementChild.style.transform = `rotate(180deg)`
    }

    // closes open menus when another is open
    this.subMenuTargets.forEach(elem => {
      if (elem.dataset.index !== element.dataset.index) {
        elem.classList.remove('is-open')
      }
    })
    // resets all other sub menu triangles when another is open
    this.rotateSubMenuTargets.forEach(elem => {
      if (elem !== e.currentTarget.firstElementChild) {
        elem.style.transform = `none`
      }
    })

    // resets height
    this.newHeightTarget.style.minHeight = `0px`
  }

  openNestedSubMenu(e) {
    const element = e.currentTarget.nextElementSibling // menu.nestedSubMenu
    element.classList.toggle('is-open')

    // changes the height of mobile-navigation__list to fit nested menu height
    const offsetBy =
      Number(e.target.closest('[data-target="menu.subMenu"]').dataset.index) +
      Number(e.currentTarget.dataset.index)
    const elementHeight = element.offsetHeight + 15 + 36 * offsetBy
    if (this.newHeightTarget.style.minHeight === `${elementHeight}px`) {
      this.newHeightTarget.style.minHeight = `0px`
    } else {
      this.newHeightTarget.style.minHeight = `${elementHeight}px`
    }

    // rotates arrow
    if (e.currentTarget.firstElementChild.style.transform === `rotate(180deg)`) {
      e.currentTarget.firstElementChild.style.transform = `none`
    } else {
      e.currentTarget.firstElementChild.style.transform = `rotate(180deg)`
    }

    // resets all other nested triangles when another is open
    this.rotateNestedMenuTargets.forEach(elem => {
      if (elem !== e.currentTarget.firstElementChild) {
        elem.style.transform = `none`
      }
    })

    // closes open menus when another is open
    this.nestedSubMenuTargets.forEach(elem => {
      if (elem.dataset.index !== element.dataset.index) {
        elem.classList.remove('is-open')
      }
    })
  }
}
