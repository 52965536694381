import Controller from './controller'

export default class extends Controller {
    static get targets() {
        return [
            'track', 'activeTrack',
            'minLabel', 'minLabelArrow', 'minThumb', 'minInput',
            'maxLabel', 'maxLabelArrow', 'maxThumb', 'maxInput'
        ]
    }

    initialize() {
        this.minValue = parseInt(this.element.dataset.minValue)
        this.maxValue = parseInt(this.element.dataset.maxValue)
        this.valueDifference = this.maxValue - this.minValue
        this.prefix = this.element.dataset.prefix
        this.format = this.element.dataset.format
        this.pointerMoveMinListener = this.moveMin.bind(this)
        this.pointerUpMinListener = this.releaseMinThumb.bind(this)
        this.pointerMoveMaxListener = this.moveMax.bind(this)
        this.pointerUpMaxListener = this.releaseMaxThumb.bind(this)
        this.halfThumbWidth = 8
        this.resizeActiveTrack()
        this.calculateTrackValues()
        this.moveMinByValue(parseInt(this.minInputTarget.value))
        this.moveMaxByValue(parseInt(this.maxInputTarget.value))
        this.dragging = false
    }

    calculateTrackValues() {
        this.minX = this.trackTarget.getBoundingClientRect().left
        this.maxX = this.trackTarget.getBoundingClientRect().right
        this.length = this.maxX - this.minX
    }

    selectMinThumb(e) {
        this.dragging = true
        this.calculateTrackValues()
        this.minThumbTarget.classList.add('z-50')
        this.minLabelTarget.classList.add('bg-dark-blue', 'text-white')
        this.minLabelTarget.classList.remove('bg-off-white', 'text-dark-blue')
        this.minLabelArrowTarget.classList.add('text-dark-blue')
        this.minLabelArrowTarget.classList.remove('text-off-white')
        this.maxThumbTarget.classList.remove('z-50')
        window.addEventListener('pointermove', this.pointerMoveMinListener)
        window.addEventListener('pointerup', this.pointerUpMinListener)
    }

    selectMaxThumb(e) {
        this.dragging = true
        this.calculateTrackValues()
        this.maxThumbTarget.classList.add('z-50')
        this.maxLabelTarget.classList.add('bg-dark-blue', 'text-white', 'z-50')
        this.maxLabelTarget.classList.remove('bg-off-white', 'text-dark-blue')
        this.maxLabelArrowTarget.classList.add('text-dark-blue')
        this.maxLabelArrowTarget.classList.remove('text-off-white')
        this.minThumbTarget.classList.remove('z-50')
        window.addEventListener('pointermove', this.pointerMoveMaxListener)
        window.addEventListener('pointerup', this.pointerUpMaxListener)
    }

    moveMin({ clientX }, dispatchEvent = true) {
        let pos = clientX - this.minX
        pos = pos - this.halfThumbWidth // Adjust the mouse position is the center of the thumb
        pos = Math.max(pos, 0)
        pos = Math.min(pos, this.maxThumbTarget.getBoundingClientRect().left - this.minX - 20)
        const proportion = pos / this.length;
        this.minThumbTarget.style.left = `${proportion * 100}%`
        let value = Math.round(this.minValue + (this.valueDifference * proportion))

        if (this.format === 'currency') {
            value = Math.ceil(value / 1000) * 1000;
        } else {
            value = Math.ceil(value / 10) * 10;
        }

        this.minInputTarget.value = value
        this.minInputTarget.disabled = value === this.minValue
        if (dispatchEvent) {
            this.minInputTarget.dispatchEvent(new Event('change'));
        }
        this.minLabelTarget.innerText = `${this.prefix}${new Intl.NumberFormat().format(
            this.format === 'currency' ? Math.round(value / 100) : value
        )}`
        this.resizeActiveTrack()
    }

    moveMax({ clientX }, dispatchEvent = true) {
        let pos = clientX - this.minX
        pos = pos - this.halfThumbWidth // Adjust the mouse position is the center of the thumb
        pos = Math.min(pos, this.length)
        pos = Math.max(pos, this.minThumbTarget.getBoundingClientRect().right - this.minX + 5)
        const proportion = pos / this.length;
        this.maxThumbTarget.style.left = `${proportion * 100}%`
        let value = Math.round(this.minValue + (this.valueDifference * proportion))

        if (this.format === 'currency') {
            value = Math.ceil(value / 1000) * 1000;
        } else {
            value = Math.ceil(value / 10) * 10;
        }

        this.maxInputTarget.value = value
        this.maxInputTarget.disabled = value === this.maxValue
        if (dispatchEvent) {
            this.maxInputTarget.dispatchEvent(new Event('change'));
        }
        this.maxLabelTarget.innerText = `${this.prefix}${new Intl.NumberFormat().format(
            this.format === 'currency' ? Math.round(value / 100) : value
        )}`
        this.resizeActiveTrack()
    }

    releaseMinThumb(e) {
        this.minLabelTarget.classList.add('bg-off-white', 'text-dark-blue')
        this.minLabelTarget.classList.remove('bg-dark-blue', 'text-white')
        this.minLabelArrowTarget.classList.add('text-off-white')
        this.minLabelArrowTarget.classList.remove('text-dark-blue')
        window.removeEventListener('pointermove', this.pointerMoveMinListener)
        window.removeEventListener('pointerup', this.pointerUpMinListener)
        this.dragging = false
    }

    releaseMaxThumb(e) {
        this.maxLabelTarget.classList.add('bg-off-white', 'text-dark-blue')
        this.maxLabelTarget.classList.remove('bg-dark-blue', 'text-white')
        this.maxLabelArrowTarget.classList.add('text-off-white')
        this.maxLabelArrowTarget.classList.remove('text-dark-blue')
        window.removeEventListener('pointermove', this.pointerMoveMaxListener)
        window.removeEventListener('pointerup', this.pointerUpMaxListener)
        this.dragging = false
    }

    resizeActiveTrack() {
        this.activeTrackTarget.style.left = this.minThumbTarget.style.left
        const difference = this.maxThumbTarget.getBoundingClientRect().left - this.minThumbTarget.getBoundingClientRect().left;
        this.activeTrackTarget.style.width = `${(difference / this.length) * 100}%`
    }

    minValueChanged(e) {
        if (this.dragging) { return }
        this.calculateTrackValues()
        const value = e.value === null || e.value === undefined ? this.minValue : e.value
        this.moveMinByValue(value, false)
    }

    maxValueChanged(e) {
        if (this.dragging) { return }
        this.calculateTrackValues()
        const value = e.value === null || e.value === undefined ? this.maxValue : e.value
        this.moveMaxByValue(value, false)
    }

    moveMinByValue(value, dispatchEvent) {
        if (value === null || value === undefined) {
            value = this.minValue;
        }
        value = Math.max(value, this.minValue)
        const proportion = (value - this.minValue) / this.valueDifference
        let pos = this.minX + this.halfThumbWidth + (proportion * this.length)
        this.moveMin({ clientX: pos }, dispatchEvent)
        this.minInputTarget.value = value
    }

    moveMaxByValue(value, dispatchEvent) {
        if (value === null || value === undefined) {
            value = this.maxValue;
        }
        value = Math.min(value, this.maxValue)
        const proportion = (value - this.minValue) / this.valueDifference
        let pos = this.minX + this.halfThumbWidth + (proportion * this.length)
        this.moveMax({ clientX: pos }, dispatchEvent)
        this.maxInputTarget.value = value
    }
}
