import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return ['select', 'filter', 'results']
  }

  filterResults(e) {
    if (e.currentTarget === this.selectTarget) {
      this.filterTargets.forEach(checkbox => {
        checkbox.disabled = true
      })
    } else {
      this.selectTarget.disabled = true
    }

    this.filterTargets.forEach(checkbox => {
      checkbox.parentNode.classList.remove('is-active')
    })

    e.currentTarget.parentNode.classList.add('is-active')
    this.resultsTarget.style.opacity = '0.4'

    e.currentTarget.closest('form').submit()
  }
}
