import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return ['countryFilter', 'topicFilter']
  }

  filterByCountry() {
    if (this.countryFilterTarget.value) {
      this.countryFilterTarget.form.submit()
    } else {
      window.location = window.location.pathname
    }
  }

  filterByTopic() {
    if (this.countryFilterTarget.value) {
      this.topicFilterTarget.form.action = this.topicFilterTarget.value
      this.topicFilterTarget.form.submit()
    } else {
      window.location = this.topicFilterTarget.value
    }
  }
}
