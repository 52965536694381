import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return ['price', 'priceUnavailable']
  }

  initialize() {
    this.updatePrice()
  }

  updatePrice() {
    this.selectedCurrency = window.localStorage.getItem('currency') || 'gbp'

    this.priceTargets.forEach(price => {
      price.classList.add('hidden')
    })

    this.selectedPrices.forEach(price => {
      price.classList.remove('hidden')
    })

    this.priceUnavailableTarget.classList.toggle('hidden', this.selectedPrices.length)
  }

  get selectedPrices() {
    return this.priceTargets.filter(price => price.dataset.currency === this.selectedCurrency)
  }
}
