import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return ['input', 'selectedDestinations', 'options', 'template']
  }

  initialize() {
    const selectedDestinations = document.createElement('ul')
    const options = document.createElement('select')
    const optionsWrapper = document.createElement('div')
    const blankOption = document.createElement('option')
    const addDestinations = document.createElement('span')

    selectedDestinations.dataset.target = 'multi-select.selectedDestinations'
    selectedDestinations.className = 'inline-block'

    blankOption.innerText = 'Add destination'
    blankOption.value = ''
    blankOption.selected = true
    blankOption.disabled = true

    options.dataset.target = 'multi-select.options'
    options.dataset.action = 'multi-select#select'
    options.classList.add('multiselect')
    options.setAttribute('aria-label', 'Choose one or more destinations')
    options.innerHTML = this.inputTarget.innerHTML
    options.prepend(blankOption)

    optionsWrapper.classList.add('multiselect-wrapper')

    this.inputTarget.style.display = 'none'

    optionsWrapper.appendChild(addDestinations)
    optionsWrapper.appendChild(options)

    this.inputTarget.parentNode.appendChild(selectedDestinations)
    this.inputTarget.parentNode.appendChild(optionsWrapper)
  }

  select() {
    // hide the destination options select
    this.optionsTarget.style.display = 'none'

    // redraw the selected destinations
    this.toggleSelected(this.optionsTarget.options[this.optionsTarget.selectedIndex].text)

    // select the 'placeholder' option in the destination options select
    this.optionsTarget.querySelector(`[value='']`).selected = true
  }

  remove(e) {
    this.toggleSelected(e.currentTarget.parentNode.dataset.value)
  }

  toggleSelected(value) {
    const inputOption = Array.from(this.inputTarget.options).find(op => op.text === value)
    const optionsOption = Array.from(this.optionsTarget.options).find(op => op.text === value)

    if (inputOption.selected) {
      inputOption.selected = false
    } else {
      inputOption.selected = true
      this.inputTarget.removeChild(inputOption)
      this.inputTarget.appendChild(inputOption)
    }

    optionsOption.style.display = optionsOption.style.display === 'none' ? 'block' : 'none'

    this.selectedDestinationsTarget.innerHTML = ''

    this.inputTarget.querySelectorAll('option').forEach(option => {
      if (option.selected) {
        this.addSelectedDestination(option)
      } else {
        this.optionsTarget.style.display = 'inline-block'
      }
    })
  }

  addSelectedDestination(option) {
    const content = this.templateTarget.content.cloneNode(true)

    content.querySelector('span').innerText = option.text
    content.querySelector('li').dataset.value = option.text

    this.selectedDestinationsTarget.appendChild(content)
  }
}
