import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return ['tab', 'underline', 'page']
  }

  selectTab(event) {
    const index = Number(event.target.dataset.index)
    for (let i = 0; i < this.tabTargets.length; i++) {
      if (i === index) {
        this.tabTargets[i].classList.add('text-raw-sienna')
        this.underlineTargets[i].classList.remove('opacity-0')
        this.pageTargets[i].classList.remove('hidden')
      } else {
        this.tabTargets[i].classList.remove('text-raw-sienna')
        this.underlineTargets[i].classList.add('opacity-0')
        this.pageTargets[i].classList.add('hidden')
      }
    }
  }
}
