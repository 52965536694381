import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return ['button', 'offScreenTrigger']
  }

  initialize() {
    this.onScroll(this)
  }

  onScroll(self) {
    const clientHeight = self.offScreenTriggerTarget.getBoundingClientRect().bottom
    if (clientHeight < -12) {
      self.buttonTarget.classList.remove('pointer-events-none', 'opacity-0')
    } else {
      self.buttonTarget.classList.add('pointer-events-none', 'opacity-0')
    }
  }

  onScrollListener(self) {
    return e => this.onScroll(self, e)
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  connect() {
    window.addEventListener('scroll', this.onScrollListener(this))
  }

  disconnect() {
    window.removeEventListener('scroll', this.onScrollListener(this))
  }
}
