import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return ['trigger', 'menu']
  }

  initialize() {
    if (this.triggerTargets.length) {
      this.triggerTargets[0].classList.add('is-open')
    }

    if (this.menuTargets.length) {
      this.menuTargets[0].classList.add('is-open')
    }
  }

  change(event) {
    event.preventDefault()

    this.triggerTargets.forEach(target => {
      target.classList.remove('is-open')
    })

    this.menuTargets.forEach(target => {
      target.classList.remove('is-open')
    })

    const trigger = event.target.closest('[data-target=subnav\\.trigger]')
    const index = this.triggerTargets.indexOf(trigger)

    trigger.classList.add('is-open')
    this.menuTargets[index].classList.add('is-open')
  }
}
