// eslint-disable-next-line import/prefer-default-export
export const lazyLoad = () => {
  const lazyLoadImages = document.querySelectorAll('[data-lazyload]')
  for (let i = 0; i < lazyLoadImages.length; i++) {
    const lazyLoadImage = lazyLoadImages[i]

    const notMobile = lazyLoadImage.hasAttribute('data-lazyload-notmobile')
    if (!notMobile || window.innerWidth >= 768) {
      const src = lazyLoadImage.getAttribute('data-src')
      if (src) {
        lazyLoadImage.removeAttribute('data-src')
        lazyLoadImage.setAttribute('src', src)
      }
    }
  }
}

window.addEventListener('load', lazyLoad)
window.addEventListener('resize', lazyLoad, true)
