import { Controller } from 'stimulus'

export default class extends Controller {
  initialize() {
    window.addEventListener('scroll', () => {
      if (this.element.contains(document.activeElement)) {
        document.activeElement.blur()
      }
    })
  }

  clickToFocus(ev) {
    ev.target.focus()
  }

  keyClose(ev) {
    if (ev.keyCode === 27 && this.element.contains(document.activeElement)) {
      document.activeElement.blur()
    }
  }
}
